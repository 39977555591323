import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import getEnvVariable from '../utils/environment';
import { ENVIRONMENT } from '../constants';
import {
  EMAIL_ALREADY_EXISTS_ERROR,
  useCreateAccount
} from '../utils/unifiedSignup';
import FullPageLoader from '../components/FullPageLoader';
import {
  Alert,
  Anchor,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text
} from '@twilio-paste/core';
import { AcceptIcon } from '@twilio-paste/icons/esm/AcceptIcon';
import { ArrowBackIcon } from '@twilio-paste/icons/esm/ArrowBackIcon';
import { LinkExternalIcon } from '@twilio-paste/icons/esm/LinkExternalIcon';
import showFreeTrialInConsole from '../utils/flags/showFreeTrialInConsole';
import { ConfirmationModal } from '../components/ConfirmationModal';

const auth0Audience = getEnvVariable(ENVIRONMENT.AUTH0_AUDIENCE);
const getTokenParams: GetTokenSilentlyOptions = {
  authorizationParams: {
    audience: auth0Audience
  }
};

const SG_WEBSITE_URL = getEnvVariable(ENVIRONMENT.SG_WEBSITE_URL);
const LOGIN_HOST_URL = getEnvVariable(ENVIRONMENT.LOGIN_HOST_URL);
const TWILIO_CONSOLE_URL = getEnvVariable(ENVIRONMENT.TWILIO_CONSOLE_URL);

const TWILIO_SENDGRID_LOGO =
  'https://uiux-assets.sendgrid.com/logo/sg-twilio-lockup.svg';
const LOGO_ALT = 'Twilio SendGrid Logo';

export const UnifiedLoginSignup = () => {
  const { loginWithRedirect, getAccessTokenSilently, isAuthenticated, user } =
    useAuth0();
  const { createAccount, isError, isLoading, errorMsg } = useCreateAccount();
  const history = useHistory();
  const [confirm, setConfirm] = useState<boolean>(false);

  useEffect(() => {
    const getToken = async () => {
      if (!isAuthenticated) {
        loginWithRedirect({
          appState: { targetUrl: '/unified_login/signup' }
        });
      }
    };
    getToken();
  }, [isAuthenticated, loginWithRedirect]);

  const createNewAccount = useCallback(async() => {
    setConfirm(false);
    const getTokenAndCreate = async () => {
      const { id_token, access_token } = await getAccessTokenSilently({
        ...getTokenParams,
        detailedResponse: true
      });
      const success = await createAccount(access_token, id_token);
      if (success) {
        history.replace('/unified_login/account_details');
      }
    };
    await getTokenAndCreate();
  }, [createAccount, getAccessTokenSilently, setConfirm]);

  const onConfirmationLaunch = useCallback(() => {
    setConfirm(true);
  }, [setConfirm]);

  const onCancel = useCallback(() => {
    setConfirm(false);
  }, [setConfirm]);

  const goBackToTwilioConsole = useCallback(() => {
    window.location.href = TWILIO_CONSOLE_URL;
  }, []);

  const loginToExistingAccount = useCallback(() => {
    setConfirm(false);
    window.location.href = `${LOGIN_HOST_URL}/login/identifier`;
  }, [confirm]);

  const clickHereToLogin = (
    <a
      onClick={(e) => {
        e.preventDefault();
        window.location.replace(`${LOGIN_HOST_URL}/login/identifier`);
      }}
      data-testid="click-here-to-login"
    >
      {'here'}
    </a>
  );

  if (!isAuthenticated) {
    return <FullPageLoader />;
  }

  return (
    <>
      <Box
        display={'flex'}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        width={'100%'}
      >
        {isError ? (
          <Box width={'100%'}>
            <Alert variant="warning">
              <strong>Unable to create account.</strong> {errorMsg}{' '}
              {errorMsg === EMAIL_ALREADY_EXISTS_ERROR ? (
                <>Please login {clickHereToLogin}.</>
              ) : null}
            </Alert>
          </Box>
        ) : (
          <Box width={'100%'}>
            <Alert variant="neutral">
              Looking to request access to your company&apos;s SendGrid account?
              Contact your SendGrid admin to get invited.
            </Alert>
          </Box>
        )}
        <Box
          display={'flex'}
          flexDirection={'column'}
          padding={'space150'}
          borderStyle={'solid'}
          borderWidth={'borderWidth10'}
          borderRadius={'borderRadius30'}
          borderColor={'colorBorderWeaker'}
          width={'864px'}
          marginTop={'space160'}
        >
          <Box paddingBottom={'space70'}>
            <img
              alt={LOGO_ALT}
              src={TWILIO_SENDGRID_LOGO}
              height={40}
              width={137}
            />
          </Box>
          <Box paddingBottom={'space70'}>
            <Heading as="h3" variant="heading30" marginBottom="space0">
              {showFreeTrialInConsole()
                ? 'Welcome to the SendGrid Console! Start your 60-day free trial now'
                : 'Welcome to SendGrid Console'}
            </Heading>
            <Text as="span">{user?.email}</Text>
          </Box>
          <Box paddingBottom={'space60'}>
            <Box paddingBottom={'space30'}>
              <Heading as="h5" variant="heading40" marginBottom="space0">
                You can now:
              </Heading>
            </Box>
            <Flex paddingBottom={'space20'}>
              <div style={{ color: '#53bd94' }}>
                <AcceptIcon decorative color={'inherit'} />
              </div>
              <Text as="span" fontSize={'fontSize30'} paddingLeft={'space60'}>
                Use your Twilio credentials and two-factor authentication to sign
                in to SendGrid
              </Text>
            </Flex>
            <Flex>
              <div style={{ color: '#53bd94' }}>
                <AcceptIcon decorative color={'inherit'} />
              </div>
              <Text as="span" fontSize={'fontSize30'} paddingLeft={'space60'}>
                Manage your user settings for all Twilio products from the Twilio
                Console
              </Text>
            </Flex>
          </Box>
          <Box paddingBottom={'space60'}>
            <Box paddingBottom={'space30'}>
              <Heading as="h5" variant="heading40" marginBottom="space0">
                Your free SendGrid plan includes:
              </Heading>
            </Box>
            <Flex paddingBottom={'space20'}>
              <div style={{ color: '#53bd94' }}>
                <AcceptIcon decorative color={'inherit'} />
              </div>
              <Text as="span" fontSize={'fontSize30'} paddingLeft={'space60'}>
                100 emails/day
              </Text>
            </Flex>
            <Flex>
              <div style={{ color: '#53bd94' }}>
                <AcceptIcon decorative color={'inherit'} />
              </div>
              <a href={`${SG_WEBSITE_URL}/pricing`}>
                <Box color={'colorTextLink'} display={'flex'}>
                  <Text
                    as="span"
                    fontSize={'fontSize30'}
                    paddingLeft={'space60'}
                    color={'inherit'}
                  >
                    And more!
                  </Text>
                  <LinkExternalIcon decorative />
                </Box>
              </a>
            </Flex>
          </Box>
          <Text as="span" fontSize={'fontSize30'}>
            By continuing, we will create a free SendGrid account for you to get
            started!
          </Text>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            paddingTop={'space70'}
          >
            <Button
              variant={'secondary'}
              onClick={goBackToTwilioConsole}
              data-testid="go-back-button"
            >
              <ArrowBackIcon decorative />
              Go Back
            </Button>
            <Stack orientation={'horizontal'} spacing="space40">
              <Button
                variant={'secondary'}
                onClick={loginToExistingAccount}
                data-testid="login-existing-account-button"
              >
                Login to existing SendGrid account
              </Button>
              <Button
                variant={'primary'}
                onClick={onConfirmationLaunch}
                loading={isLoading}
                data-testid="create-account-button"
              >
                Create new account
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box paddingTop={'space160'}>
          <Text as={'p'} fontSize={'fontSize20'} color={'colorTextWeak'}>
            By continuing, you agree to the{' '}
            <Anchor href={`${SG_WEBSITE_URL}/policies/tos`} target="_blank">
              Terms of Service
            </Anchor>{' '}
            and{' '}
            <Anchor
              href={`${SG_WEBSITE_URL}/policies/privacy/services-privacy-policy`}
              target="_blank"
            >
              Privacy Notice
            </Anchor>
            .
          </Text>
        </Box>
      </Box>
      <ConfirmationModal showModal={confirm} onConfirm={createNewAccount} onCancel={onCancel}/>
    </>
  );
};
