import React from 'react';
import { Button } from '@twilio-paste/core/button';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PersonalInfo from './PersonalInfo';
import Honeypot from './Honeypot';
import withProfileInputs from '../containers/withProfileInputs';
import withPackages from '../containers/withPackages';
import {
  getEmailApiPlan,
  getMarketingCampaignsPlan
} from '../utils/packageHelper';
import { WriteSelectors } from './profileForm-qa-hooks';
import { ArrowForwardIcon } from '@twilio-paste/icons/cjs/ArrowForwardIcon';
import {
  DetailText,
  Heading,
  Topbar,
  TopbarActions,
  Box
} from '@twilio-paste/core';
import { CustomizationProvider } from '@twilio-paste/core/customization';

const SendgridLogo =
  'https://uiux-assets.sendgrid.com/logo/sg-twilio-lockup.svg';

export class ProfileForm extends React.Component {
  componentDidMount() {
    this.props.setPageLoading();
    const statusPromise = this.props.fetchStatus();
    const profileInputsPromise = this.props.prefillProfileInputs(
      this.props.unifiedUser
    );
    Promise.all([statusPromise, profileInputsPromise]).then(() =>
      this.props.setPageLoadingFinished()
    );
    this.dispatchEmailMethod(this.props.packages);
  }

  dispatchEmailMethod(packages) {
    if (
      !packages ||
      !packages.activePackage ||
      packages.activePackage.length === 0
    ) {
      return;
    }
    const selectedMCPlan = getMarketingCampaignsPlan(packages.activePackage);
    const selectedEIPlan = getEmailApiPlan(packages.activePackage);

    // Since every user defaults to trials for 60 days it's hard to know intent anymore,
    // this was once based on what type of paid package a user selected while signing up
    if (
      selectedEIPlan &&
      selectedEIPlan.id === 'sg.ei.trial.v1' &&
      selectedMCPlan &&
      selectedMCPlan.id === 'sg.mc.trial.v1'
    ) {
      return;
    }

    if (selectedEIPlan) {
      this.props.toggleIsTransactional();
    }
    if (selectedMCPlan) {
      this.props.toggleIsMarketing();
    }
  }

  render() {
    return (
      <Box
        width={'100%'}
        height={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        {!this.props.unifiedUser ? (
          <Topbar id="profile-form-header">
            <img
              width={'155px'}
              height={'35px'}
              src={SendgridLogo}
              alt="SendGrid Logo"
            />
          </Topbar>
        ) : (
          <Box />
        )}
        <Box maxWidth={'638px'} alignSelf={'center'} width={'100%'}>
          <DetailText>Help us serve you better</DetailText>
          <Heading as="h1" variant="heading10">
            Tell us about yourself
          </Heading>
          <PersonalInfo unifiedUser={this.props.unifiedUser} />
          <Honeypot />
        </Box>
        <Box alignSelf={'flex-end'} width={'100%'}>
          <CustomizationProvider
            elements={{
              TOPBAR: {
                backgroundColor: 'colorBackgroundBody',
                borderTopStyle: 'solid',
                borderTopColor: 'colorBorderWeaker',
                borderTopWidth: 'borderWidth10'
              }
            }}
          >
            <Topbar id="profile-form-footer">
              <TopbarActions justify="end">
                <Button
                  variant={'primary'}
                  onClick={() =>
                    this.props.onSave(
                      this.props.profileInputs,
                      this.props.history
                    )
                  }
                  data-role="save-button"
                  disabled={!this.props.profileInputs.isValidForm}
                  {...WriteSelectors.getStartedButton}
                >
                  Next
                  <ArrowForwardIcon decorative={false} title="Next" />
                </Button>
              </TopbarActions>
            </Topbar>
          </CustomizationProvider>
        </Box>
      </Box>
    );
  }
}

ProfileForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  toggleIsTransactional: PropTypes.func.isRequired,
  toggleIsMarketing: PropTypes.func.isRequired,
  profileInputs: PropTypes.shape({
    isValidForm: PropTypes.bool.isRequired
  }).isRequired,
  fetchStatus: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  packages: PropTypes.shape({
    activePackage: PropTypes.array.isRequired,
    activeIsFree: PropTypes.bool.isRequired,
    packagesLoaded: PropTypes.bool.isRequired
  }).isRequired,
  unifiedUser: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  })
};

export default withRouter(withPackages(withProfileInputs(ProfileForm)));
