import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
  Paragraph,
} from "@twilio-paste/core";
import { useUID } from "react-uid";
import { TwilioCreateNewAccount } from "../illustrations/TwilioCreateNewAccount";

export type ConfirmationModalProps = {
  showModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmationModal = ({ showModal, onConfirm, onCancel }: ConfirmationModalProps) => {
  const ModalId = useUID();

  return (
    <Modal
      isOpen={showModal}
      onDismiss={onCancel}
      size={"default"}
      ariaLabelledby={ModalId}
      data-role="confirmation-modal">
      <ModalHeader>
        <ModalHeading as="h3" id={ModalId} data-role="modal-heading">
          Create new account
        </ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Box>
          <Box textAlign={"center"} paddingBottom={"space60"}>
            <TwilioCreateNewAccount/>
          </Box>
          <Box
            display={"flex"}
            flex={1}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingBottom={"space90"}>
            <Box textAlign={"start"}>
              <Paragraph>
                Proceeding with creating a new account will create a new SendGrid account that is not associated to any
                other SendGrid accounts. If you are part of a corporate account, please contact your SendGrid admin to be
                invited as a teammate to that existing SendGrid account.
              </Paragraph>
              <Paragraph marginBottom="space0">
                If you have already been invited as a teammate, check your teammate invitation sent to your email address
                and accept the invitation.
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <Button variant="secondary" onClick={onCancel} data-role="cancel-button">
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm} data-role="confirm-button">
            Proceed with new account
          </Button>
        </ModalFooterActions>
      </ModalFooter>
    </Modal>
  );
};
